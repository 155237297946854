/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Sumit",
  logo_name: "Sumit.s()",
  nickname: "Sumit",
  full_name: "Sumit Samadhiya",
  subTitle:
    "Full Stack Developer, Always learning.",
  resumeLink:
    "https://drive.google.com/file/d/18NTSA1Ma0OVlpM6lwSz3cejYD9R8gqg7/view",
  mail: "mailto:sumitsamadhiya37@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/Sumit-Samadhiya",
  linkedin: "https://www.linkedin.com/in/sumit-samadhiya-0613b725a/",
  gmail: "sumitsamadhiya37@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "",
  instagram: "https://www.instagram.com/sumit_samadhiya_3/?next=%2F",
};

const skills = {
  data: [
    {
      title: "FrontEnd Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building visually stunning and user-friendly web experiences.",
        "⚡ Building responsive website using HTML, CSS, JS, Tailwind And ReactJs",
        
        "⚡ Crafting responsive and interactive interfaces.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#5c79df",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
       
        
       
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
      
      ],
    },

    {
      title: "BackEnd Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Certified Django Developer. ",
        "⚡ Experience working on multiple server-side applications.",
        "⚡ Building robust and scalable server-side applications with Django.",
        
      ],
      softwareSkills: [
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
       
     
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },

        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            backgroundColor: "transparent",
            color: "#092E20"
          }
        }
      ],
    },

    {
      title: "Other Skills",
      fileName: "FullStackImg",
      skills: [
        "⚡ C language: Where my coding journey began. ",
        "⚡ Diving deep into object-oriented programming with C++.",
        "⚡ Laying the groundwork in Java for future innovation.",
        "⚡ From theory to practice: Implementing DSA in C++.",
        "⚡ Git: Tracking changes and managing projects with precision",
        "⚡ From data design to query execution: MySQL expertise.",
        
        
      ],
      softwareSkills: [
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#A8B9CC",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#00599C",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "devicon:java",
          style: {
            color: "#007396",
          },
        },
        
        {
          skillName: "DSA",
          fontAwesomeClassname: "simple-icons:codeforces",
          style: {
            color: "#1F8ACB",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        
      ],
    },

  ],
};

const degrees = {
  degrees: [
    {
      title: "GURU NANAK INSTITUTIONS TECHNICAL CAMPUS",
      subtitle: "HACKTOPIA",
      logo_path: "dal.png",
      alt_name: "DAL",
      duration: "25 August - 26 August 2023",
      descriptions: [
        "⚡ Proud to have been part of Hackatopia! So many innovative ideas and great teamwork. 💡",
        "⚡ Thrilled to have completed Hackatopia with such a fantastic team! 🌟",
        "⚡ Wrapping up an intense and rewarding journey at Hackathopia! 🎉",
        "⚡ I have Achieved 7th rank. 🎉",
      ],
      website_link: "https://drive.google.com/file/d/1AqDbXBpUV1ZTlkQ7skevdXB71y2BzU5P/view",
    },

    {
      title: "I.T.M UNIVERSITY",
      subtitle: "Smart India Hackathon",
      logo_path: "",
      alt_name: "",
      duration: "September 2023",
      descriptions: [
        "⚡ Thrilled to have participated in the Smart India Hackathon!",
        "⚡ An amazing experience at the Smart India Hackathon, where I honed my skills in managing work pressure and leading a team. 🚀",
        "⚡Grateful for the experience! 🌟 ",
      ],
      website_link: "https://drive.google.com/file/d/1tHff315_4I_4Kq9g_ZSSAwxLCOBPSwXm/view",
    },

    {
      title: "Data Flair",
      subtitle: "C Certificate",
      logo_path: "",
      alt_name: "",
      duration: "July 2023",
      descriptions: [
        "⚡ Achievement unlocked: C language certification complete! 💻",
        "⚡ Certification complete! Grateful for the knowledge gained and excited for the challenges ahead. 🚀",
        "⚡ From understanding pointers to building efficient algorithms, my C language journey has been rewarding. 💡 ",
        
      ],
      website_link: "https://drive.google.com/file/d/1Comuw-qClisehzs1LjO5OYPSnI6ZMA2-/view",
    },

    {
      title: "SCALER",
      subtitle: "C++ Certificate",
      logo_path: "",
      alt_name: "",
      duration: "July 2023",
      descriptions: [
        "⚡ Celebrating the completion of my C++ certification! Ready to code with confidence. 💻",
        "⚡ Mastered the power of C++ and earned my certification. Here's to endless coding possibilities! 🚀",
        "⚡ Level up: C++ certification achieved! Grateful for the journey and excited for what's next. 💡 ",
        
      ],
      website_link: "https://drive.google.com/file/d/1MD8jVtomIoFMmUu4vIOMZSJAy5owW9Y8/view",
    },

    {
      title: "UDEMY",
      subtitle: "Full Stack BootCamp",
      logo_path: "",
      alt_name: "",
      duration: "Febuary 2024",
      descriptions: [
        "⚡ Proud to announce I've finished my Web Development Bootcamp. Can't wait to start building amazing websites! 🌟",
        "⚡ Web Development Bootcamp: Check! Eager to apply my skills to create dynamic and responsive web applications. 🚀",
        "⚡ From HTML to full-stack mastery—Bootcamp completed and excited for the journey ahead! 💻",
      ],
      website_link: "https://drive.google.com/file/d/1v-KKEBal6p455nZiA46Qsc0ewm_ut-E6/view",
    },
    
    {
      title: "HackerRank",
      subtitle: "SQL Certificate",
      logo_path: "",
      alt_name: "",
      duration: "July 2024",
      descriptions: [
        "⚡ Excited to share that I’ve successfully cleared the SQL assessment on HackerRank! 💻",
        "⚡ Happy to share my latest achievement: SQL certification on HackerRank! 🚀",
        "⚡ Excited to apply these skills in upcoming projects. 💡 ",
        
      ],
      website_link: "https://drive.google.com/file/d/1vUG3Es2dyLxbbCdv_NKvW9b3aBZd4XE8/view",
    },




    

  ],
};

const certifications = {
  certifications: [
   
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship ",
  description:
    "I've completed two internships. I've mostly done projects on my own and I am actively looking for internships. I love share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
   
    {
      title: "Internships",
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Tech StudyCell",
          company_url: "https://drive.google.com/file/d/1IiTU1GSe1fgvmpBxw-BQw4n-76UmkvCJ/view",
          logo_path: "mylog.png",
          duration: "June 2023 - Aug 2023",
          location: "Jhansi , Uttar Pradesh",
          description:
            "I worked on the Dashboard project which helps users track their activities while using Automation System. Where anyone can track and control their electrical appliances",
          color: "#ee3c26",
        },
        
        
        {
          title: "Front-End Developer",
          company: "The Website Makers",
          company_url: "https://drive.google.com/file/d/1JtFnlQMon4Uoq63fK6ZZVBqE19sIhZuH/view",
          logo_path: "vjt.png",
          duration: "September 2020",
          location: "",
          description: "I have experienced in developing and maintaining web applications using HTML, CSS, and JavaScript and react.",
          color: "#800000",
        },

        {
      title: "Other Skills",
      fileName: "FullStackImg",
      skills: [
        "⚡ C language: Where my coding journey began. ",
        "⚡ Diving deep into object-oriented programming with C++.",
        "⚡ Laying the groundwork in Java for future innovation.",
        "⚡ From theory to practice: Implementing DSA in C++.",
        "⚡ Git: Tracking changes and managing projects with precision",
        "⚡ From data design to query execution: MySQL expertise.",
        
        
      ],
      softwareSkills: [
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#A8B9CC",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#00599C",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "devicon:java",
          style: {
            color: "#007396",
          },
        },
        
        {
          skillName: "DSA",
          fontAwesomeClassname: "simple-icons:codeforces",
          style: {
            color: "#1F8ACB",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        
      ],
    },

      ],
    },
   
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Web Applications projects. Below are some of my projects.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "sumit1.png",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "My Resume",
    subtitle:
      "Here's my journey in tech so far. Take a look at my resume to learn more about my work and skills.",
    link: "https://drive.google.com/file/d/18NTSA1Ma0OVlpM6lwSz3cejYD9R8gqg7/view",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "Cafaous",
      url: "https://github.com/Lokendrasingh2003/Cafaous.git",
      description: "Restaurants application.",
      languages: [
        {
          "name": "HTML5",
          "iconifyClass": "vscode-icons:file-type-html"
        },
        {
          "name": "CSS3",
          "iconifyClass": "vscode-icons:file-type-css"
        },
        {
          "name": "JavaScript",
          "iconifyClass": "logos-javascript"
        },
        
      ],
    },
    {
      name: "FUNEVT",
      url: "https://github.com/Lokendrasingh2003/FUNEVT.git",
      description:
        "It is a client-side application where anyone can buy a ticket for singing conert.",
      languages: [
        {
          "name": "HTML5",
          "iconifyClass": "vscode-icons:file-type-html"
        },
        {
          "name": "CSS3",
          "iconifyClass": "vscode-icons:file-type-css"
        },
        {
          "name": "JavaScript",
          "iconifyClass": "logos-javascript"
        },
      ],
    },
    {
      name: "Wedding Planner",
      url: "https://github.com/Lokendrasingh2003/Wedding_Planner.git",
      description: "It is a web application where anyone can book their wedding destination and other facilities.",
      languages: [
        {
          "name": "HTML5",
          "iconifyClass": "vscode-icons:file-type-html"
        },
        {
          "name": "CSS3",
          "iconifyClass": "vscode-icons:file-type-css"
        },
        {
          "name": "JavaScript",
          "iconifyClass": "logos-javascript"
        },
      ],
    },
    {
      name: "opPortfolio",
      url: "https://github.com/Hrishi1999/opPortfolio",
      description: "(This) A clean and full customizable portfolio template.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Cab Security System",
      url: " ",
      description:
        "Driver drowsiness detection, profanity detection, violence detection, SMS alerts, cab driver tracking.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "OpenCV",
          iconifyClass: "logos-opencv",
        },
      ],
    },
    {
      name: "Lensinator",
      url: " ",
      description:
        "Published on Play Store back in 2017, crossed 55K downloads. Performed object detection, image captioning, OCR, handwritten OCR, barcode and QR code scanning with product information.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-java",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "Doorbell.io",
      url: "https://github.com/Hrishi1999/Doorbell.io",
      description:
        "A sound-classifier webapp made with ReactJS + TensorflowJS.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "TensorflowJS",
          iconifyClass: "logos-tensorflow",
        },
      ],
    },
    {
      name: "Video Summarization",
      url: "https://github.com/Hrishi1999/Video-Summarization",
      description:
        "A video summarization webapp. A Flask-based backend which servers the Tensorflow model and a frontend made in ReactJS.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
      ],
    },
    {
      name: "Dashboard Activity",
      url: "https://github.com/Hrishi1999/Dashboard.activity",
      description:
        "Google Summer of Code project. A Dashboard activity for Sugar OS which tracks user activity so user can know where they use their most time and where they create most files.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Project Pickle",
      url: " ",
      description:
        "An app similar to Uber Eats but targeted for Gruhudhyogs (homemade pickles and products). Order management, live order tracking, rating and reviews system, etc.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Project Health Card",
      url: "",
      description:
        "An aadhard-card like utility card which keeps track of all your health records suchs as: Medicines, Lab Reports, Recent Diseases, Allergies, etc.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-java",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      name: "Food Classifier",
      url: " ",
      description:
        "A food classifier app using tflite which classfier over 50 Indian Dishes.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Dart",
          iconifyClass: "logos-dart",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      name: "Image Clustering",
      url: " ",
      description:
        "A simple use of K-Means Clustering algorithm to sort images by automatically predicting number of classes. This can be used to clean an image dataset.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      name: "Antivirus",
      url: " ",
      description:
        "A project from 2012. Made an antivirus with realtime scanning. Implemented by using comparison of md5 hashes.",
      languages: [
        {
          name: "Visual Basic",
          iconifyClass: "logos-dotnet",
        },
      ],
    },
    {
      name: "Sugar on Windows",
      url: " ",
      description:
        "Run and setup Sugar OS to be able to run on Windows using Windows Subsystem for Linux",
      languages: [
        {
          name: "Visual Basic",
          iconifyClass: "logos-dotnet",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Object + Face Detection",
      url: " ",
      description: "2017 project. Object detection + accurate face detection.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "OpenCV",
          iconifyClass: "logos-opencv",
        },
      ],
    },
    {
      name: "Image Captioning with Visual Attention",
      url: " ",
      description: "Ongoing. Used MSCOCO 2014/2017 for image captioning.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Tensorflow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      name: "DalSocial",
      url: " ",
      description:
        "An Android app to meet new people in the university, organize and join events. ",
      languages: [
        {
          name: "Kotlin",
          iconifyClass: "simple-icons:kotlin",
          color: "#F18E33",
        },
        {
          name: "Android",
          iconifyClass: "simple-icons:android",
          color: "#3DDC84",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "HalifaxFoodie",
      url: " ",
      description:
        "CSCI5410 Project. A multi-client recipe app for Halifax Foodies.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "AWS",
          iconifyClass: "logos-aws",
        },
        {
          name: "Google Cloud",
          iconifyClass: "logos-google-cloud",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
